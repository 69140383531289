import React, { FC } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { MainCard } from "client/components/MainCard";
import styled from "styled-components/macro";

const LegalCard = styled(MainCard)`
  ul:not(last-child),
  & > ol > li {
    margin-bottom: 1rem;
  }
`;

export const TermsOfUse: FC<Props> = props => {
  return (
    <LegalCard>
      <h1>Terms of Use of Use</h1>
      <p>
        These terms of use (&ldquo; Terms of Use &rdquo;) govern your access to
        and use of the flink-packages.org website (&ldquo; Website &rdquo;)
        operated by Ververica GmbH (&ldquo; Ververica &rdquo;) and the
        information made available via the Website (collectively hereinafter the
        &ldquo; Services &rdquo;).
      </p>
      <p>
        Throughout the Terms of Use, &ldquo;we,&rdquo; &ldquo;us,&rdquo;
        &ldquo;our&rdquo; and &ldquo;ours&rdquo; refer to Ververica, and
        &ldquo;you,&rdquo; &ldquo;your&rdquo; or &ldquo;yours&rdquo; refer to
        you personally (i.e., the individual who reads and agrees to be bound by
        these Terms of Use). If you access the Services on behalf of your
        employer or another legal entity, the terms &ldquo;you,&rdquo;
        &ldquo;your&rdquo; or &ldquo;yours&rdquo; refer to that entity. If you
        are using the Services on behalf of any entity you represent and warrant
        that you are authorized to accept these Terms of Use on such
        entity&rsquo;s behalf. By accepting these Terms of Use, you are hereby
        binding such entity to the Terms of Use.
      </p>
      <ol>
        <li>
          <strong>ACCEPTANCE OF TERMS OF USE</strong>
          <ol type="a">
            <li>
              Ververica provides the Services to you conditioned upon your
              accepting all of the Terms of Use.
            </li>
            <li>
              We reserve the right to change, modify, add to, or remove parts of
              these Terms of Use in our sole discretion at any time. We will in
              such case communicate the modification on our Website. Any
              modification shall be effective immediately upon making available
              the modified Terms of Use on our Website.
            </li>
            <li>
              You declare your agreement to be bound by any such modification by
              continuing to use or access the Services after modified Terms of
              Use are posted on the Website. If the modified Terms of Use are
              not acceptable to you, you must discontinue your use of our
              Services.
            </li>
          </ol>
        </li>
        <li>
          <strong>LOGIN THROUGH GITHUB</strong>
          <ol type="a">
            <li>
              Ververica offers the opportunity to login on our Website for
              submitting content. The login is performed via GitHub. The
              registration, account management etc. is handled by GitHub. For
              further details please refer to our{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>.
            </li>
            <li>
              Ververica will not receive your GitHub login credentials. The
              Service will retrieve your public GitHub account information based
              on a temporary access token generated by GitHub upon login. You
              must notify Ververica immediately of any unauthorized use of your
              access token.
            </li>
          </ol>
        </li>

        <li>
          <strong>RIGHT TO USE THE SERVICES</strong>
          <ol type="a">
            <li>
              Provided that you comply with these Terms of Use, Ververica grants
              you a limited, non-exclusive, non-transferable, revocable license
              to access and use the Services during the term of your agreement
              with Ververica.
            </li>
            <li>
              Except as otherwise specifically noted in these Terms of Use, all
              content, images, documents, and other information made available
              by Ververica through the Website (collectively, the &ldquo;
              Ververica Content &rdquo;), regardless of its source or creation,
              is owned by or licensed by the respective owner to Ververica.
              Ververica reserves and retains all rights in and to such Ververica
              Content.
            </li>
            <li>
              The Ververica Content may be protected by copyright and trademark
              laws, and other intellectual property rights. Except as expressly
              set forth in these Terms of Use, these Terms of Use do not grant
              to you any license to any intellectual property rights or other
              rights.
            </li>
            <li>
              Except strictly as necessary for you to access the Services for
              the intended purpose pursuant to these Terms of Use, you may not
              copy, modify, create derivative, translate, transmit, publish,
              display, download, sublicense, transfer, dispose of, distribute,
              or sell the Ververica Content or any other part of the Services.
            </li>
          </ol>
        </li>
        <li>
          <strong>CONTRIBUTIONS SUBMITTED BY YOU</strong>
          <ol type="a">
            <li>
              If you have successfully logged in, you are permitted to provide
              Contributions (as defined in the next sentence) to the Services.
            </li>
            <li>
              &ldquo; Contributions &rdquo; are defined as any data or material
              you may submit or otherwise make available for display on the
              Website including, without limitation, any links, graphics and
              texts describing the software (the &ldquo; Linked Software
              &rdquo;).
            </li>
            <li>
              Unless otherwise specifically agreed to by you and Ververica, by
              uploading or otherwise making available any Contribution, you
              hereby acknowledge that such Contribution is non-confidential. In
              addition, you grant to Ververica a perpetual, irrevocable,
              worldwide, non-exclusive, sub-licensable, fully paid-up and
              royalty-free license to use, make, have made, offer for sale,
              sell, copy, distribute, perform, display, modify, adapt, publish,
              transmit and otherwise exploit such Contribution. This includes
              the right to use and exploit such Contribution by means of any
              form, medium, or technology now known or later developed, and to
              grant to others rights to do any of the foregoing.
            </li>
            <li>
              For each Contribution, you represent and warrant that you have all
              rights necessary for you to grant the license granted in the
              preceding paragraph, and that such Contribution, and your
              provision thereof to and through the Services, does not violate
              any privacy, publicity, contractual, intellectual property, or
              other right or rights of any person or entity or otherwise violate
              any applicable laws.
            </li>
            <li>
              Ververica does not pre-screen or monitor Contributions and does
              not control Contributions. Therefore, Ververica does not guarantee
              the accuracy, quality or appropriateness of Contributions and
              disclaims any responsibility for Contributions, including any
              liability for errors or omissions, or for any loss or damage of
              any kind incurred as a result of their use.
            </li>
            <li>
              However, Ververica reserves the right at its sole discretion to
              refuse or delete Contributions, provided that even if we do remove
              any Contribution, we shall have no obligation to terminate our
              other uses of such Contribution or any other Contribution as
              permitted above.
            </li>
            <li>
              We have no obligation to store any of your Contributions. We have
              no responsibility or liability for the deletion or failure to
              store, transmit or receive your Contributions, nor do we have any
              responsibility for the security, privacy, storage or transmission
              of other communications originating with or involving your use of
              the Services, except as may be expressly stated in these Terms of
              Use or in the Privacy Policy.
            </li>
            <li>
              You are solely responsible for creating backup copies of and
              replacing any Contributions at your sole cost and expense.
            </li>
          </ol>
        </li>
        <li>
          <strong>NO SOFTWARE LICENSE</strong>
          <ol type="a">
            <li>
              Ververica does not grant to you any license to the Linked Software
              on the Website. Neither does Ververica endorse any Linked Software
              or any other Contributions.
            </li>
            <li>
              Your rights to access and use any Linked Software will be subject
              to your agreement with the respective contributor of such Linked
              Software (the &ldquo; Software License &rdquo;) identified in the
              Linked Software or our Website.
            </li>
            <li>
              You must not use any Linked Software unless you agree to be bound
              by all terms and conditions of any applicable Linked Software
              License.
            </li>
            <li>
              If there is a conflict between these Terms of Use and any Software
              License, the conflicting term of the Software License shall
              control but only to the extent necessary to eliminate the
              conflict.
            </li>
          </ol>
        </li>
        <li>
          <strong>LINKS</strong>
          <ol type="a">
            <li>
              In addition to the links provided by our users, we may from
              time-to-time, in our sole discretion, offer or provide links to
              third party services, products, web pages, websites or other
              content (&ldquo; Third Party Content &rdquo;).
            </li>
            <li>
              This is provided solely as a convenience to our users, including
              you. This does not imply that we incorporate, endorse, approve,
              control or adopt such Third Party Content. We make no claims or
              representations regarding, and accept no responsibility or
              liability for, any Third-Party Content. Use of Third Party Content
              is at your own risk.
            </li>
            <li>
              If you leave the Services to access Third Party Content through a
              link provided by our users or Ververica, you should be aware that
              our policies, including the Privacy Policy, no longer apply. We
              recommend that you review the applicable terms and policies,
              including the privacy and data collection policies, of each
              website that you access from the Services. You may not link to the
              Services from any website without our prior written permission.
            </li>
          </ol>
        </li>
        <li>
          <strong>USAGE RESTRICTIONS</strong>
          <ol type="a">
            <li>
              You will not (i) make any Service or Ververica Content available
              to anyone other than permitted hereunder, (ii) use the Service to
              store or transmit infringing, libelous, or otherwise unlawful or
              tortious material, or to store or transmit material in violation
              of third-party privacy rights, (iii) use a Service to store or
              transmit malicious code, (iv) interfere with or disrupt the
              integrity or performance of any Service or third-party data
              contained therein, (v) attempt to gain unauthorized access to any
              Service or Ververica Content, (vi) modify, copy, or create
              derivative works based on a Service or any part, feature, function
              or user interface thereof, (vii) copy Ververica Content except as
              permitted herein, (viii) frame or mirror any part of any Service
              or Ververica Content, (ix) except to the extent permitted by
              applicable law, disassemble, reverse engineer, or decompile a
              Service or Ververica Content.
            </li>
          </ol>
        </li>
        <li>
          <strong>DISCLAIMER OF WARRANTIES</strong>
          <ol type="a">
            <li>
              You expressly agree that your use of the Services, including any
              Ververica Content, is at your own risk. All Services are provided
              to you on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo;
              basis. Ververica makes no representations or warranties of any
              kind, express or implied, about the Services.
            </li>
            <li>
              To the extent that applicable national law does not permit the
              exclusions and disclaimers of warranties as asset in this clause
              8, some or all of the above exclusions and disclaimers may not
              apply to you, in which case such exclusions and disclaimers will
              apply to the full extent allowed by the applicable national law.
              You acknowledge that these exclusions and disclaimers shall not be
              affected by any expiration or termination of these terms or your
              use of the Services.
            </li>
          </ol>
        </li>
        <li>
          <strong>LIMITATION OF LIABILITY</strong>
          <ol type="a">
            <li>
              In case of wilful misconduct or gross negligence, Ververica shall
              be liable according to the statutory provisions of applicable law.
            </li>
            <li>
              In case of ordinary negligence, Ververica shall &nbsp;&ndash;
              provided that the standard of liability is not limited according
              to statutory provisions of applicable law (such as any limitation
              to the duty of care observed in own affairs) &ndash; only be
              liable for breach of material contractual obligations (material
              contractual obligations are obligations the breach of which
              endangers the purpose of the agreement and the fulfilment of which
              you generally rely and may reasonably rely on); in this case
              Ververica&rsquo;s liability shall be limited to the typical
              damages that were reasonably foreseeable. Therefore, indirect and
              consequential damages resulting from failures of the Services are
              only eligible for compensation if such damages are typical and
              reasonably foreseeable and when the Services are used in
              conformity with their intended purpose.
            </li>
            <li>
              The aforementioned limitations do not apply to (i) damages
              resulting from injury to life, body or health, (ii) liability
              pursuant to the German Product Liability Act
              (&ldquo;Produkthaftungsgesetz&rdquo;), (iii) the assumption of a
              guarantee for the condition of goods and/or work or fraudulent
              concealment of defects by Ververica.
            </li>
            <li>
              The aforementioned limitations of liability shall apply to (i) any
              liability claims for whatever legal reason but in particular due
              to impossibility, default, defective or incorrect delivery, breach
              of contract, breach of obligations in contractual negotiations and
              tort, as far as such claims are subject to fault, and (ii) any
              breach of duty by vicarious agents or any other person for whose
              conduct Ververica can be held liable according to the statutory
              provisions of applicable law.
            </li>
            <li>
              You agree that we will not be liable for delays, failures, or
              inadequate performance of the Services resulting from conditions
              outside of our reasonable control, including but not limited to
              natural disasters or other acts of God, failure of
              telecommunications networks or any other network or utility,
              threatened or actual acts of terrorism or war, riots, labor
              strikes, or governmental acts or orders.
            </li>
          </ol>
        </li>
        <li>
          <strong>INDEMNIFICATION</strong>
          <ol type="a">
            <li>
              To the full extent permitted by law, you agree to indemnify,
              defend and hold harmless Ververica, its officers, directors,
              shareholders, successors in interest, employees, agents,
              subsidiaries and affiliates, from and against any and all actual
              or threatened third party claims (unsubstantiated or otherwise),
              demands, losses, damages, costs and liabilities, proceedings
              (lawful or equitable) and expenses (including reasonable
              attorneys&rsquo; and experts&rsquo; fees and investigation costs)
              arising out of or in connection with (i) your use of the Services,
              (ii) your breach of these Terms of Use, (iii) your violation of
              any law, regulation or right of any third party or (iv) your
              disclosure, solicitation or use of any personal information,
              whether with or without your knowledge or consent.
            </li>
            <li>
              However, Ververica reserves the right to assume the exclusive
              defense and control of any matter otherwise subject to
              indemnification by you, in which case you agree to cooperate with
              Ververica in defending that claim, and in no event shall you agree
              to any settlement that affects Ververica without Ververica&rsquo;s
              prior written consent.
            </li>
          </ol>
        </li>
        <li>
          <strong>SUSPENSION OR TERMINATION OF ACCESS TO OUR SERVICES</strong>
          <ol type="a">
            <li>
              Notwithstanding any other provision to the contrary in these Terms
              of Use, you acknowledge that Ververica, in its sole discretion and
              with or without prior notification, for any or no reason, may
              suspend or terminate your access and/or block your future access
              to any or all of the Services, including without limitation for
              any of the following reasons: (i) if we determine that you have
              violated any provision of these Terms of Use or the intent
              expressed therein, (ii) in response to a request from any
              governmental authority, (iii) due to suspension or adjustment of
              any of the Services, or (iv) due to unforeseen technical
              difficulties.
            </li>
            <li>
              Ververica cannot be held liable by you or any third party for any
              termination of your access to any part of the Services.
            </li>
            <li>
              Those terms of these Terms of Use, which by their nature should
              remain in effect, shall survive any termination of your use of the
              Services.
            </li>
          </ol>
        </li>
        <li>
          <strong>GENERAL</strong>
          <ol type="a">
            <li>
              The Terms of Use and the relationship between each user and
              Ververica shall be governed by the laws of Germany without regard
              to its conflict of law provisions and each party shall submit to
              the personal and exclusive jurisdiction of the courts located in
              Berlin, Germany.
            </li>
            <li>
              Titles and headings of sections of these Terms of Use are for
              convenience only and shall not affect the construction of any
              provision of these Terms of Use.
            </li>
            <li>
              The parties are independent contractors. This agreement does not
              create a partnership, franchise, joint venture, agency, or
              employment relationship between the parties. If any provision of
              these Terms of Use is held by a court of competent jurisdiction to
              be contrary to law, the provision will be deemed null and void,
              and the remaining provisions of these Terms of Use will remain in
              effect.
            </li>
            <li>
              Neither party may assign any of its rights or obligations
              hereunder, whether by operation of law or otherwise, without the
              other party&rsquo;s prior written consent (not to be unreasonably
              withheld); provided, however, either party may assign these Terms
              of Use in its entirety (including all order forms), without the
              other party&rsquo;s consent to its affiliate or in connection with
              a merger, acquisition, corporate reorganization, or sale of all or
              substantially all of its assets. Notwithstanding the foregoing, if
              a party is acquired by, sells substantially all of its assets to,
              or undergoes a change of control in favor of, a direct competitor
              of the other party, then such other party may terminate these
              Terms of Use upon written notice. Subject to the foregoing, these
              Terms of Use will bind and inure to the benefit of the parties,
              their respective successors and permitted assigns.
            </li>
          </ol>
        </li>
      </ol>
    </LegalCard>
  );
};

type Props = RouteComponentProps;
